import React, { useMemo } from 'react';
import { Button, Col, Flex, Row, Select, Space, Spin, Typography } from 'antd';
import Search from 'antd/es/input/Search';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { apiUrl } from '../../api/requests';
import { Cohort } from '../../types/cohort';
import _ from 'lodash';
import { AttendanceModel } from '../../types/attendance';
import { AttendanceEdit } from './attendance-edit';

const { Title, Text } = Typography;
const { Option } = Select;

export const AttendanceEditListPage = () => {
    const [currentCohort, setCurrentCohort] = React.useState<number | null>(
        null
    );
    const [currentStudent, setCurrentStudent] =
        React.useState<AttendanceModel | null>(null);
    const [searchTerm, setSearchTerm] = React.useState('');

    const { data: cohorts, isPending: cohortsLoading } = useQuery({
        queryKey: ['cohorts'],
        queryFn: async () => {
            const result = await axios.get<Cohort[]>(`${apiUrl}/cohorts`, {
                withCredentials: false,
            });

            return _.uniq(result.data.map((c) => c.cohort_number));
        },
    });

    const { data: attendance, isSuccess: attendanceSuccess } = useQuery({
        queryKey: ['attendance', currentCohort],
        queryFn: async () => {
            const result = await axios.get<AttendanceModel[]>(
                `${apiUrl}/cohorts/attendance/${currentCohort}`,
                {
                    withCredentials: false,
                }
            );

            return result.data;
        },
        enabled: currentCohort !== null,
    });

    const filteredAttendances = useMemo(() => {
        return attendance?.filter(
            (a) =>
                (a.student_name &&
                    a.student_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) ||
                (a.student_surname &&
                    a.student_surname
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()))
        );
    }, [attendance, searchTerm]);

    const onSelectStudent = (s: AttendanceModel) => {
        setCurrentStudent(s);
    };

    const onSelectCohort = (cohort: number) => {
        setCurrentCohort(cohort);
        setCurrentStudent(null);
    };

    const onSearch = (term: string) => {
        setSearchTerm(term);
        setCurrentStudent(null);
    }

    return (
        <Spin spinning={cohortsLoading}>
            <Flex gap="middle" vertical>
                <Flex gap="middle" justify="space-between">
                    <Title level={3} style={{ margin: 0 }}>
                        Посещаемость
                    </Title>
                </Flex>

                <Row gutter={[12, 12]}>
                    <Col lg={3} xs={12}>
                        <Flex vertical>
                            <Text type="secondary">Поток: </Text>
                            <Select onChange={onSelectCohort}>
                                {cohorts?.map((c) => (
                                    <Option value={c} key={c}>
                                        {c}
                                    </Option>
                                ))}
                            </Select>
                        </Flex>
                    </Col>
                    <Col sm={24} xs={24} lg={6}>
                        <Flex vertical>
                            <Text type="secondary">Поиск: </Text>
                            <Search
                                disabled={!currentCohort}
                                onChange={(e) => onSearch(e.target.value)}
                                allowClear
                            />
                        </Flex>
                    </Col>
                </Row>
                {currentStudent ? (
                    <Flex vertical>
                        <Space>
                            <Button
                                type="link"
                                onClick={() => setCurrentStudent(null)}
                            >
                                {'< '}Назад
                            </Button>
                        </Space>
                        <AttendanceEdit attendance={currentStudent} />
                    </Flex>
                ) : (
                    <>
                        {attendanceSuccess && (
                            <Row gutter={[24, 24]}>
                                {filteredAttendances?.map((a, index) => (
                                    <Button
                                        color="primary"
                                        variant="text"
                                        key={a.id}
                                        onClick={() => onSelectStudent(a)}
                                    >
                                        {a.student_surname} {a.student_name}
                                    </Button>
                                ))}
                            </Row>
                        )}
                    </>
                )}
            </Flex>
        </Spin>
    );
};
