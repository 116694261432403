import React from 'react';
import { Button, Flex, Form, Input, message, Typography } from 'antd';
import { Cohort, GroupData } from '../../types/cohort';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { apiUrl } from '../../api/requests';
import { useParams } from 'react-router-dom';
import { PageSpinner } from '../../components/page-spinner';

const { Title } = Typography;

export const Group = () => {
    const { id } = useParams();
    const [messageApi, contextHolder] = message.useMessage();

    const {
        data: group,
        isPending,
        isSuccess,
    } = useQuery({
        queryKey: ['group', id],
        queryFn: async () => {
            const result = await axios.get(`${apiUrl}/cohorts/${id}`, {
                withCredentials: false,
            });
            return result.data as Cohort;
        },
    });

    const { mutate: saveGroup, isPending: isPendingSave } = useMutation({
        mutationFn: (newData: GroupData) =>
            axios.put(`${apiUrl}/cohorts/${id}`, newData, {
                withCredentials: false,
            }),
        onSuccess: () => {
            messageApi.open({
                type: 'success',
                content: 'Данные сохранены',
            });
        },
    });

    const onSave = (data: GroupData) => {
        saveGroup(data);
    };

    return (
        <>
            {isPending && <PageSpinner />}
            {isSuccess && (
                <Flex gap="middle" vertical>
                    {contextHolder}
                    <Flex gap="middle" justify="space-between">
                        <Title level={3} style={{ margin: 0 }}>
                            Редактирование группы
                        </Title>
                    </Flex>
                    <Form
                        disabled={isPendingSave}
                        layout="vertical"
                        style={{ maxWidth: 300 }}
                        initialValues={group}
                        onFinish={onSave}
                    >
                        <Form.Item<GroupData>
                            name="group_number"
                            label="Номер группы"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item<GroupData>
                            name="telegram_group_id"
                            label="Telegram group id"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item<GroupData>
                            name="description"
                            label="Описание группы"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                Сохранить
                            </Button>
                        </Form.Item>
                    </Form>
                    {/*<Flex gap={12}>*/}
                    {/*    <Button type="default">Добавить всех опытных</Button>*/}
                    {/*    <Button type="default">Добавить всех новичков</Button>*/}
                    {/*</Flex>*/}
                </Flex>
            )}
        </>
    );
};
