import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { apiUrl } from '../../api/requests';
import { Cohort, CohortList } from '../../types/cohort';
import { Button, Flex, Typography } from 'antd';
import { PageSpinner } from '../../components/page-spinner';
import _ from 'lodash';
import { CohortCard } from './cohorts-card';

const { Title, Text } = Typography;

export const Cohorts: React.FC = () => {
    const {
        data: cohorts,
        isPending: cohortsLoading,
        isSuccess: cohortsSuccess,
        refetch: getCohorts,
    } = useQuery({
        queryKey: ['cohorts'],
        queryFn: async () => {
            const result = await axios.get(`${apiUrl}/cohorts`, {
                withCredentials: false,
            });
            return result.data as Cohort[];
        },
    });

    const { mutate: createNewGroup } = useMutation({
        mutationFn: () =>
            axios.post(`${apiUrl}/cohorts/`, {
                withCredentials: false,
            }),
        onSuccess: () => getCohorts(),
    });

    const { mutate: activateCohort } = useMutation({
        mutationFn: (cohortId: number) =>
            axios.post(`${apiUrl}/cohorts/activate/${cohortId}`, {
                withCredentials: false,
            }),
        onSuccess: () => getCohorts(),
    });

    const { mutate: addNewGroup } = useMutation({
        mutationFn: (cohortId: number) =>
            axios.post(`${apiUrl}/cohorts/add_group/${cohortId}`, {
                withCredentials: false,
            }),
        onSuccess: () => getCohorts(),
    });

    const preparedCohorts: CohortList[] = useMemo(() => {
        return _(cohorts)
            .groupBy('cohort_number')
            .map((items, cohort_number) => ({
                cohort_number: Number(cohort_number),
                is_active: items[0].is_active,
                group_data: items
                    .map(
                        ({
                            id,
                            telegram_group_id,
                            description,
                            group_number,
                        }) => ({
                            id,
                            telegram_group_id,
                            description,
                            group_number,
                        })
                    )
                    .sort((a, b) => a.group_number - b.group_number),
            }))
            .value()
            .sort((a, b) => b.cohort_number - a.cohort_number);
    }, [cohorts]);

    return (
        <Flex gap="middle" vertical>
            <Flex gap="middle" justify="space-between">
                <Title level={3} style={{ margin: 0 }}>
                    Потоки
                </Title>

                <Flex gap={12}>
                    <Button type="primary" onClick={() => createNewGroup()}>
                        Добавить новый поток
                    </Button>
                </Flex>
            </Flex>

            <Flex gap={24} wrap>
                {cohortsLoading && <PageSpinner />}
                {cohortsSuccess &&
                    preparedCohorts?.map((cohort) => (
                        <CohortCard
                            cohort={cohort}
                            activateCohort={activateCohort}
                            addNewGroup={addNewGroup}
                        />
                    ))}
                {cohortsSuccess && !cohorts?.length && (
                    <Text>Ничего не найдено</Text>
                )}
            </Flex>
        </Flex>
    );
};
