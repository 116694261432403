import React from 'react';
import { DiaryStatus } from '../../types/diary-status';
import { Flex, Typography } from 'antd';
import { Spinner } from '../spinner';
import { getIcon } from '../../helpers/get-attendance-icon';

const { Text } = Typography;

type DiaryStatusProps = {
    loading: boolean;
    success: boolean;
    data?: DiaryStatus;
};

export const DiaryStatusCard: React.FC<DiaryStatusProps> = ({
    loading,
    success,
    data,
}) => {
    return (
        <div>
            {loading && <Spinner />}

            {success && !data && <div>Нет данных</div>}

            {success && !!data && (
                <Flex vertical>
                    <Text>
                        Заполнен весь дневник{' '}
                        {data.is_finished_diary ? '✅' : '❌'}
                    </Text>
                    <Text>1 урок {getIcon(data.lessons.lesson_1)}</Text>
                    <Text>2 урок {getIcon(data.lessons.lesson_2)}</Text>
                    <Text>3 урок {getIcon(data.lessons.lesson_3)}</Text>
                    <Text>4 урок {getIcon(data.lessons.lesson_4)}</Text>
                    <Text>5 урок {getIcon(data.lessons.lesson_5)}</Text>
                    <Text>6 урок {getIcon(data.lessons.lesson_6)}</Text>
                    <Text>7 урок {getIcon(data.lessons.lesson_7)}</Text>
                    <Text>8 урок {getIcon(data.lessons.lesson_8)}</Text>
                    <Text>9 урок {getIcon(data.lessons.lesson_9)}</Text>
                    <Text>
                        Посещены/отработаны занятия{' '}
                        {data.lessons.is_finished_all_lessons ? '✅' : '❌'}
                    </Text>
                </Flex>
            )}
        </div>
    );
};
