import { DefaultOptionType } from 'antd/es/select';
import React, { useState } from 'react';
import { Select } from 'antd';
import { Button, Cascader, CascaderOption } from 'antd-mobile';
import { BrowserView, MobileView } from 'react-device-detect';

const days = Array.from({ length: 49 }, (_, index) => index + 1);
const daysOptions: DefaultOptionType[] = days.map((d) => ({
    value: d,
    label: d,
}));

type DaySelectProps = {
    value?: number;
    onChange?: (day: number) => void;
};

export const DaySelect: React.FC<DaySelectProps> = ({ value, onChange }) => {
    const [visible, setVisible] = useState(false);
    return (
        <>
            <BrowserView>
                <Select
                    size="large"
                    value={value}
                    onChange={onChange}
                    showSearch
                    placeholder="Выберите день"
                    optionFilterProp="value"
                    options={daysOptions}
                />
            </BrowserView>
            <MobileView>
                <Button
                    onClick={() => {
                        setVisible(true);
                    }}
                >
                    Выберите день
                </Button>
                <Cascader
                    title="Выберите день"
                    options={daysOptions as CascaderOption[]}
                    visible={visible}
                    onConfirm={(value) =>
                        onChange && onChange(value[0] as number)
                    }
                    onClose={() => {
                        setVisible(false);
                    }}
                    cancelText="Отмена"
                    confirmText="Применить"
                    placeholder="День"
                />
            </MobileView>
        </>
    );
};
