import React, {useState} from 'react';
import {Card, Col, Flex, message, Select, Space, Typography} from 'antd';
import { AttendanceModel } from '../../types/attendance';
import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {apiUrl} from "../../api/requests";
import {getIcon} from "../../helpers/get-attendance-icon";

const { Text } = Typography;
const { Option } = Select;


type LessonType = {
    title: string;
    value:
        | 'lesson_1'
        | 'lesson_2'
        | 'lesson_3'
        | 'lesson_4'
        | 'lesson_5'
        | 'lesson_6'
        | 'lesson_7'
        | 'lesson_8'
        | 'lesson_9';
};

const lessons: LessonType[] = [
    { title: '1 Урок', value: 'lesson_1' },
    { title: '2 Урок', value: 'lesson_2' },
    { title: '3 Урок', value: 'lesson_3' },
    { title: '4 Урок', value: 'lesson_4' },
    { title: '5 Урок', value: 'lesson_5' },
    { title: '6 Урок', value: 'lesson_6' },
    { title: '7 Урок', value: 'lesson_7' },
    { title: '8 Урок', value: 'lesson_8' },
    { title: '9 Урок', value: 'lesson_9' },
];

const lesson_options = [
    { title: 'Был', value: 10 },
    { title: 'Отработал', value: 5 },
    { title: 'На проверке', value: 1 },
    { title: 'Не был', value: 0 },
];

type AttendanceEditProps = {
    attendance: AttendanceModel;
};

export const AttendanceEdit: React.FC<AttendanceEditProps> = ({ attendance }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [attendanceData, setAttendanceData] = useState<AttendanceModel>(attendance);

    const {
        mutate: edit,
        isPending: isEditPending,
    } = useMutation({
        mutationFn: (entity: { lesson_key: string, value: number }) =>
            axios.put(`${apiUrl}/cohorts/attendance/${attendance.id}`, entity, {
                withCredentials: false,
            }),
        onSuccess: (data) => {
            messageApi.success('Сохранено!');
            setAttendanceData(data.data)
        },
        onError: () => {
            messageApi.error('Произошла ошибка');
        }
    });

    const onChange = (lesson_key: string, value: number) => {
        edit({ lesson_key, value })
    };

    return (
        <Col xs={24} md={12} lg={8} xl={6}>
            {contextHolder}
            <Card
                title={`${attendanceData.student_surname} ${attendanceData.student_name}`}
            >
                <Flex vertical gap={12}>
                    <Space>
                        <Text type="secondary">Поток:</Text>
                        <Text>{attendanceData.cohort_number}</Text>
                        <Text type="secondary">Группа:</Text>
                        <Text>{attendanceData.group_number}</Text>
                    </Space>

                    {lessons.map((l) => (
                        <Flex vertical key={l.value}>
                            <Text type="secondary">{l.title}</Text>
                            <Select disabled={isEditPending} value={attendanceData[l.value]} onChange={(v) => onChange(l.value, v)}>
                                {lesson_options.map((o) => (
                                    <Option
                                        value={o.value}
                                        key={`${l.value}${o.value}`}
                                    >
                                        {o.title} {getIcon(o.value)}
                                    </Option>
                                ))}
                            </Select>
                        </Flex>
                    ))}

                    {/*<Text type="secondary">1 урок:</Text>*/}
                    {/*<Radio.Group>*/}
                    {/*    <Radio value={10}>Был</Radio>*/}
                    {/*    <Radio value={5}>Отработал</Radio>*/}
                    {/*    <Radio value={1}>На проверке</Radio>*/}
                    {/*    <Radio value={0}>Не был</Radio>*/}
                    {/*</Radio.Group>*/}
                </Flex>
            </Card>
        </Col>
    );
};
