import React from 'react';
import { CohortList } from '../../types/cohort';
import {
    Button,
    Card,
    Col,
    Flex,
    Popconfirm,
    Row,
    Space,
    Typography,
} from 'antd';
import {
    SettingOutlined,
    EditOutlined,
    PlusOutlined,
    CaretRightOutlined,
} from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from '../../routes';

const { Text } = Typography;

type CohortsCardProps = {
    cohort: CohortList;
    activateCohort: (cohortId: number) => void;
    addNewGroup: (cohortId: number) => void;
};

export const CohortCard: React.FC<CohortsCardProps> = ({
    cohort,
    activateCohort,
    addNewGroup,
}) => {
    const navigate = useNavigate();

    const getStatus = (isActive: boolean) => {
        return isActive ? '🟢' : '🔴';
    };

    const onEdit = (groupId: number) => {
        navigate(generatePath(routes.editGroup, { id: groupId }));
    };

    return (
        <Col span={24}>
            <Card
                title={
                    <Flex justify="space-between">
                        Поток №: {cohort.cohort_number}{' '}
                        {getStatus(cohort.is_active)}
                        <Flex gap={12}>
                            <Button
                                type="primary"
                                onClick={() =>
                                    addNewGroup(cohort.cohort_number)
                                }
                            >
                                <PlusOutlined />
                            </Button>
                            {!cohort.is_active && (
                                <Popconfirm
                                    title="Активировать поток. Вы уверены?"
                                    description="Остальные группы станут неактивными"
                                    onConfirm={() =>
                                        activateCohort(cohort.cohort_number)
                                    }
                                    okText="Да"
                                    cancelText="Нет"
                                >
                                    <Button danger>
                                        <CaretRightOutlined />
                                    </Button>
                                </Popconfirm>
                            )}
                        </Flex>
                    </Flex>
                }
            >
                <Col span={24}>
                    <Flex gap={24} vertical>
                        <Row gutter={[16, 16]}>
                            {cohort.group_data.map((group) => (
                                <Col xl={6} lg={12} md={12} xs={24}>
                                    <Card
                                        type="inner"
                                        title={`Группа №: ${group.group_number}`}
                                        extra={
                                            <EditOutlined
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => onEdit(group.id)}
                                            />
                                        }
                                    >
                                        <Space direction="vertical">
                                            <Text type="secondary">
                                                Описание:
                                            </Text>
                                            <Text>{group.description}</Text>
                                            <Text type="secondary">
                                                Telegram:
                                            </Text>
                                            <Text>
                                                {group.telegram_group_id}
                                            </Text>
                                        </Space>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Flex>
                </Col>
            </Card>
        </Col>
    );
};
