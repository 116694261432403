export const getIcon = (value: number) => {
    switch (value) {
        case 10:
            return '🟢';
        case 5:
            return '🟡';
        case 0:
            return '🔴';
    }
};
