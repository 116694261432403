import React, { useState } from 'react';
import { Button, Col, Flex, Input, Row, Typography } from 'antd';
import { PageSpinner } from '../../components/page-spinner';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { apiUrl } from '../../api/requests';
import { Registration } from '../../types/registration';
import { AttendanceReportModel } from '../../types/attendance-report-model';
import { DiaryStatus } from '../../types/diary-status';
import { AttendanceReport } from '../../components/student/attendance';
import {Statistics} from "../../types/statistics";

const { Title, Text } = Typography;
const { Search } = Input;

export const StudentsList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentStudent, setCurrentStudent] = useState<Registration | null>(
        null
    );

    const {
        data: students,
        isPending: studentsLoading,
        isSuccess: studentsSuccess,
    } = useQuery({
        queryKey: ['students', searchTerm],
        queryFn: async () => {
            const result = await axios.get(
                `${apiUrl}/students/search?term=${searchTerm}`,
                { withCredentials: false }
            );
            return result.data as Registration[];
        },
    });

    const {
        data: attendance,
        isPending: attendanceLoading,
        isSuccess: attendanceSuccess,
    } = useQuery({
        queryKey: ['attendance', currentStudent],
        queryFn: async () => {
            const result = await axios.get(
                `${apiUrl}/students/report?telegram_id=${currentStudent?.telegram_id}`,
                { withCredentials: false }
            );
            return result.data as AttendanceReportModel[];
        },
        enabled: !!currentStudent,
    });

    const {
        data: diaryStatus,
        isPending: diaryStatusLoading,
        isSuccess: diaryStatusSuccess,
    } = useQuery({
        queryKey: ['diary-status', currentStudent],
        queryFn: async () => {
            const result = await axios.get(
                `${apiUrl}/students/diary-status?telegram_id=${currentStudent?.telegram_id}`,
                { withCredentials: false }
            );
            return result.data as DiaryStatus;
        },
        enabled: !!currentStudent,
    });

    const {
        data: statistics,
        isPending: statisticsLoading,
        isSuccess: statisticsSuccess,
    } = useQuery({
        queryKey: ['statistics', currentStudent],
        queryFn: async () => {
            const result = await axios.get(
                `${apiUrl}/students/statistics?telegram_id=${currentStudent?.telegram_id}`,
                { withCredentials: false }
            );
            return result.data as Statistics;
        },
        enabled: !!currentStudent,
    });

    const onSelectStudent = (student: Registration) => {
        setCurrentStudent(student);
    };

    const onSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        setCurrentStudent(null);
    };

    return (
        <Flex gap="middle" vertical>
            <Flex gap="middle" justify="space-between">
                <Title level={3} style={{ margin: 0 }}>
                    Студенты
                </Title>
            </Flex>

            <Row>
                <Col sm={24} xs={24} lg={12}>
                    <Search onSearch={onSearch} allowClear />
                </Col>
            </Row>

            <Flex gap={10} wrap>
                {studentsLoading && <PageSpinner />}
                {studentsSuccess &&
                    students?.map((s) => (
                        <Button
                            color="primary"
                            variant={
                                currentStudent?.telegram_id === s.telegram_id
                                    ? 'filled'
                                    : 'text'
                            }
                            key={s.telegram_id}
                            onClick={() => onSelectStudent(s)}
                        >
                            {s.surname} {s.student_name}
                        </Button>
                    ))}
                {studentsSuccess && !students?.length && (
                    <Text>Ничего не найдено</Text>
                )}
            </Flex>

            {currentStudent && (
                <AttendanceReport
                    currentStudent={currentStudent}
                    attendance={{
                        loading: attendanceLoading,
                        success: attendanceSuccess,
                        data: attendance,
                    }}
                    diaryStatus={{
                        loading: diaryStatusLoading,
                        success: diaryStatusSuccess,
                        data: diaryStatus,
                    }}
                    statistics={{
                        loading: statisticsLoading,
                        success: statisticsSuccess,
                        data: statistics
                    }}
                />
            )}
        </Flex>
    );
};
